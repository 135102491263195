<template>
    <el-container class="perms">
        <el-aside width="200px">
            <el-card header="菜单管理" class="box-card"> 
                <el-tree 
                    v-loading="menuTreeLoading"
                    :data="menuTree"
                    :props="menuTreeProps"
                    node-key="menuId"
                    ref="menuTree"
                    @current-change="menuTreeCurrentChangeHandle"
                    :default-expanded-keys="treeExpandData"
                    :filter-node-method="filterNode"
                    :accordion="true"
                    :highlight-current="true"
                    current-node-key="0"
                    :expand-on-click-node="false" 
                >
                </el-tree>
            </el-card>
        </el-aside> 
        <el-main id="reder-item-main" class="item-main">
            <div class="wxts_msg_search">
                <span>上级菜单：{{ currentMenuNode.name }}</span>
                <div class="f_t">
                    <el-button-group>
                        <el-button size="mini" type="success" @click="getDataList()" :disabled="dataListLoading">{{ $t("menus.refresh") }}</el-button>
                        <el-button size="mini" v-if="isAuth('sys:menu:save')" type="primary" @click="addOrUpdateHandle()" :disabled="dataListLoading">{{ $t("add") }}</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList" border style="width: 100%" v-loading="dataListLoading">
                <!-- <table-tree-column prop="name" header-align="center" treeKey="menuId" width="200" :label="$t('menus.name')">
                </table-tree-column> -->
                <el-table-column  prop="name" align="center" width="150"  :label="$t('menus.name')"></el-table-column>
                <el-table-column  prop="enName" align="center" width="100"  :label="$t('menus.enName')"></el-table-column>
                <!-- <el-table-column prop="parentName" header-align="center" align="center" width="150" :label="$t('menus.parentMenu')"></el-table-column> -->
                <el-table-column header-align="center" align="center" width="80" :label="$t('menus.icon')">
                    <template slot-scope="scope">
                        <icon-svg :name="scope.row.icon || ''"></icon-svg>
                    </template>
                </el-table-column>

                <el-table-column prop="type" header-align="center" align="center" width="80" :label="$t('menus.type')">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type === 0" size="small">{{ $t('menus.dic') }}</el-tag>
                        <el-tag v-else-if="scope.row.type === 1" size="small" type="success">
                            {{ $t('menus.menu') }}
                        </el-tag>
                        <el-tag v-else size="small" type="info">{{ $t('menus.button') }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="orderNum" header-align="center" align="center" width="80" :label="$t('menus.number')">
                </el-table-column>
                <el-table-column prop="app" header-align="center" align="center" :show-overflow-tooltip="true" label="应用标识">
                </el-table-column>
                <el-table-column prop="url" header-align="center" align="center" :show-overflow-tooltip="true" :label="$t('menu.url')">
                </el-table-column>
                <el-table-column prop="perms" header-align="center" align="center" width="150" :show-overflow-tooltip="true" :label="$t('menus.authTag')">
                </el-table-column>
                <el-table-column prop="enabled" header-align="center" align="center" width="70" :show-overflow-tooltip="true" :label="$t('menus.hasEnable')">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.enabled === 1" size="small" type="success">{{ $t('menus.enable') }}</el-tag>
                        <el-tag v-else size="small" type="info">{{ $t('menus.disable') }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" header-align="center" align="center" width="150" :label="$t('handle')">
                    <template slot-scope="scope">
                        <el-button type="text" class="update-button" size="small" @click="addOrUpdateHandle(scope.row.menuId)">{{ $t('update') }}</el-button>
                        <el-button type="text" class="del-button" size="small" @click="deleteHandle(scope.row)">{{ $t('delete') }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 弹窗, 新增 / 修改 -->
            <add-or-update v-if="addOrUpdateVisible" :parentMenuNode="currentMenuNode" ref="addOrUpdate" @refreshDataList="getMenuTree"></add-or-update>
        </el-main>
    </el-container>
</template>

<script>
// import TableTreeColumn from "@/components/table-tree-column";

import { treeDataTranslate } from "@/utils";
import AddOrUpdate from "./add-or-update";
import { getList, deleteMenu, getMenuTree, getListByPid } from "@/api/sys/menu";
export default {
    data() {
        return {
            dataList: [],
            dataListLoading: false,
            menuTreeLoading: false,
            addOrUpdateVisible: false, 
            menuTree: [],
            treeExpandData: [],
            menuTreeProps: {
                label: "name",
                children: "children"
            },
            currentMenuNode: {}
        };
    },
    components: {
        // TableTreeColumn,
        AddOrUpdate,
    },
    activated() {
        this.getTreeList();
    },
    methods: {
        getTreeList(){
            const _this = this;
            _this.menuTreeLoading = true;
            getMenuTree()
                .then(({ data }) => {
                    if (data && data.code == 0) {
                        _this.menuTree = data.data; 
                        if(_this.menuTree) {
                            _this.menuTree.forEach(treeNode => {
                                _this.expandMenuNode(treeNode);
                            })
                            if(!_this.currentMenuNode.menuId) {
                                _this.$nextTick(() => {
                                    _this.$refs.menuTree.setCurrentKey(_this.menuTree[0].menuId);
                                    _this.menuTreeCurrentChangeHandle(_this.menuTree[0]);
                                })
                            }
                            
                        }
                    }
                })
                .finally(() => {
                    this.menuTreeLoading = false;
                });
        },
        // 菜单树选中
        menuTreeCurrentChangeHandle(data, node) { 
            this.currentMenuNode = data; 
            this.getDataList();
        },
        //tree中的filter-node-method为检索提供服务
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        }, 
        expandMenuNode(treeNode) { 
            if(treeNode['type'] === 0) {  
                //默认展开“目录”
                this.treeExpandData.push(treeNode['menuId']);

                // if(treeNode.children) {
                //     treeNode.children.forEach(childNode => {
                //         this.expandMenuNode(childNode);
                //     })
                // }
            }
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            getListByPid({
                'parentId' : this.currentMenuNode? this.currentMenuNode.menuId: '0L'
            })
                .then(({ data }) => {
                    if (data && data.code == 0) {
                        const menuList = data.data;
                        this.dataList = menuList;
                        // this.dataList = treeDataTranslate(menuList, "menuId");
                    }
                })
                .finally(() => {
                    this.dataListLoading = false;
                });
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            });
        },
        // 删除
        deleteHandle(row) {
            const id = row.menuId;
            const name = row.name;
            this.$confirm(this.$t('menus.deleteInfo'), this.$t('prompt.title'), {
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                    type: "warning",
                })
                .then(() => {
                    deleteMenu(id).then(({ data }) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: this.$t('prompt.success'),
                                type: "success",
                                duration: 1000,
                            });
                            // this.getDataList();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
    .el-aside {
      height: calc(100vh - 108px);
      overflow: auto;
    }
  
    .item-main {
      width: calc(100% - 600px);
    }
    /*只是树形结构底下会出现滚动条，不是aside页面的*/
    .el-tree {
      width: 100%;
      height: 100%;
      overflow: scroll;
      background-color: white;
    }
  
    .el-tree>.el-tree-node {
      display: inline-block;
      min-width: 100%;
    }
  
  </style>